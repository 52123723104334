import { Box, Lockup, Text } from '@butcherbox/freezer'
import React from 'react'
import RecipeGrid from '~/components/RecipeList/RecipeGrid'
import RecipesDropdown from './RecipesDropdown/RecipesDropdown'
import type { ReviewRecipeUIProps } from './ReviewRecipe.types'
import { SeeMoreRecipesCTA } from './SeeMoreRecipesCTA'

const ReviewRecipesSaveUnSave: React.FC<ReviewRecipeUIProps> = ({
  cuts,
  purchasedSku,
  onKeywordChange,
  recipesList,
  isLoading,
}) => {
  return (
    <>
      <Lockup textAlign="center">
        <Text component="h2" variant="H1Bold">
          Recipes for you
        </Text>
        <Text component="p" variant="Body1Regular">
          See what recipes other members tried with a similar selection of cuts.
        </Text>
      </Lockup>
      <RecipesDropdown
        recipeCuts={cuts}
        searchKeywordHandler={onKeywordChange}
      />

      <Box paddingTop={24} width={'100%'}>
        <RecipeGrid
          cardWidth="fluid"
          columns={{ desktop: 2, tablet: 2, mobile: 1 }}
          gridGap={16}
          recipes={recipesList}
          recipesLoading={isLoading}
        />
      </Box>

      {purchasedSku && <SeeMoreRecipesCTA currentCut={purchasedSku} />}
    </>
  )
}

export default ReviewRecipesSaveUnSave
