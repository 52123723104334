import { Box, Flex, Grid } from '@chakra-ui/core'
import { rem } from 'design'
import TabbedView from 'design/components/TabbedView/TabbedView'
import { CTA } from 'design/components/Typography/Typography'
import { LayoutContext } from 'design/contexts/layout'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import CollapseComponent from '~/components/Collapse'
import { TEST_ID } from '~/constants/cypress'
import { RadioWithLabel } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/subcomponents/RadioWithLabel'
import { Header } from './CustomerStatsFilter.styles'

const filterMapping = (filter) => {
  switch (filter) {
    case 'total_lbs':
      return 'weight'
    default:
      return filter
  }
}

const CustomerStatsFilterUI = ({ filter, handleFilterChange }) => {
  const { isMobile } = React.useContext(LayoutContext)

  return (
    <>
      {isMobile ? (
        <CustomerStatsFilterUIMobile
          filter={filter}
          handleFilterChange={handleFilterChange}
        />
      ) : (
        <CustomerStatsFilterUIDesktop
          filter={filter}
          handleFilterChange={handleFilterChange}
        />
      )}
    </>
  )
}

const CustomerStatsFilterUIDesktop = ({ filter, handleFilterChange }) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      pt={{ tablet: rem(25) }}
      textAlign="center"
    >
      <Grid gridTemplateColumns={`${rem(150)} ${rem(80)}`}>
        <Box textAlign="end">
          <Header>your top cuts by:</Header>
        </Box>
        <Box textAlign="start">
          <Header pl={rem(5)}>{filterMapping(filter)}</Header>
        </Box>
      </Grid>
      <TabbedView
        display={{ mobile: 'none', tablet: 'block' }}
        label="Customer stats filter quantity or weight"
        mobileLayout="stacked"
        paddingBottom={16}
        paddingTop={16}
        style={{ width: rem(464) }}
        tabClick={handleFilterChange}
        tabs={[
          {
            id: 'quantity',
            tabContent: <></>,
            triggerContent: (
              <>
                <Header
                  data-cy={TEST_ID.ACCOUNT_MEMBERSHIP_REVIEW_FILTER_QUANTITY}
                  data-testid="membership-review-quantity-desktop"
                >
                  quantity
                </Header>
              </>
            ),
          },
          {
            id: 'total_lbs',
            tabContent: <></>,
            triggerContent: (
              <>
                {' '}
                <Header
                  data-cy={TEST_ID.ACCOUNT_MEMBERSHIP_REVIEW_FILTER_WEIGHT}
                  data-testid="membership-review-weight-desktop"
                >
                  weight
                </Header>
              </>
            ),
          },
        ]}
      />
    </Flex>
  )
}

const CustomerStatsFilterUIMobile = ({ filter, handleFilterChange }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const collapseTrigger = (
    <CTA mr="auto">YOUR TOP CUTS BY: {filterMapping(filter)}</CTA>
  )

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      pb={{ base: rem(25) }}
      pt={{ base: rem(25) }}
      textAlign="center"
    >
      <Box
        backgroundColor="white"
        pb={rem(16)}
        pt={rem(16)}
        px={rem(16)}
        width="100%"
      >
        <CollapseComponent
          buttonStyleProps={{
            color: 'slate',
            textVariant: 'CTA',
            size: 'fluid',
            display: 'flex',
            'data-cy': TEST_ID.EXPAND_DETAILS,
          }}
          collapsedText={collapseTrigger}
          expandedText={collapseTrigger}
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Box aria-label="Cut Filters" bg="white">
            <Formik
              enableReinitialize
              initialValues={{
                filter: filter,
              }}
              onSubmit={() => {}}
            >
              <Form>
                <Field
                  aria-label="weight"
                  aria-selected={filter === 'total_lbs'}
                  component={RadioWithLabel}
                  data-testid="membership-review-weight-mobile"
                  label={<Header>weight</Header>}
                  name="filter"
                  onChange={() => handleFilterChange('total_lbs')}
                  type="radio"
                  value="total_lbs"
                />
                <Field
                  aria-label="quantity"
                  aria-selected={filter === 'quantity'}
                  component={RadioWithLabel}
                  data-testid="membership-review-quantity-mobile"
                  label={<Header>quantity</Header>}
                  labelProps={{ fontFamily: 'Lato' }}
                  name="filter"
                  onChange={() => handleFilterChange('quantity')}
                  type="radio"
                  value="quantity"
                />
              </Form>
            </Formik>
          </Box>
        </CollapseComponent>
      </Box>
    </Flex>
  )
}

export default CustomerStatsFilterUI
