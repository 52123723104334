import { rem, styled } from 'design'
import colors from 'design/colors'
import { CardHTMLImage } from 'design/components/CardImage/CardImage'
import { H2Bold, H5 } from 'design/components/Typography/Typography'
import React from 'react'
import { PurchasedSku } from '~/bb-api/schemata'
import Round from 'lodash.round'

const parseFilter = (filter, stat: PurchasedSku) => {
  switch (filter) {
    case 'total_lbs':
      return `${Round(stat.total_lbs, 2)} lbs`
    case 'quantity':
      return `${stat.quantity}x`
    default:
      return ''
  }
}

interface ReviewListItemProps {
  stat: PurchasedSku
  rank: number
  filter: 'quantity' | 'total_lbs'
}

const ReviewCutsFullListUI: React.FC<ReviewListItemProps> = ({
  stat,
  rank,
  filter,
}) => {
  return (
    <ListItemContainer>
      <H2Bold color="bb.spicedCrimson" paddingRight={rem(40)} width={rem(32)}>
        {rank.toString().padStart(2, '0')}
      </H2Bold>

      <CardHTMLImage
        as="div"
        href={stat.image}
        maxHeight={rem(32)}
        maxWidth={rem(48)}
        role="presentation"
      />

      <H5 paddingLeft={rem(8)} textAlign="left">
        {stat.description}
      </H5>

      <H5
        backgroundColor={colors.bb.openSea}
        borderRadius={rem(4)}
        color="white"
        marginLeft="auto"
        px={rem(8)}
        py={rem(5)}
      >
        {parseFilter(filter, stat)}
      </H5>
    </ListItemContainer>
  )
}

export default ReviewCutsFullListUI

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  padding: ${rem(8)} ${rem(16)};
  &:first-of-type {
    box-shadow: none;
  }
  ${(p) => p.theme.media.tablet} {
    &:first-of-type {
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    }
  }
`
