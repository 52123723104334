import { rem, styled } from 'design'
import colors from 'design/colors'

export const Container = styled.div`
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: ${rem(125)};
  padding: ${rem(16)} ${rem(8)};
  ${(p) => p.theme.media.tablet} {
    flex-direction: column;
    min-hiegth: ${rem(265)};
    padding: ${rem(16)};
  }
`

export const StatsContainer = styled.div`
  color: ${colors.bb.spicedCrimson};
  font-family: Abril Fatface;
  font-size: ${rem(80)};
  font-weight: 500;
  line-height: ${rem(56)};
  padding-right: ${rem(5)};

  ${(p) => p.theme.media.tablet} {
    line-height: ${rem(154)};
    padding-right: ${rem(0)};
  }
  ${(p) => p.theme.media.desktop} {
    font-size: ${rem(115)};
  }
`
export const MeatStyleContainer = styled.div`
  color: ${colors.bb.spicedCrimson};
  line-height: ${rem(56)};
  padding-right: ${rem(5)};

  ${(p) => p.theme.media.tablet} {
    line-height: ${rem(154)};
    padding: ${rem(32)} ${rem(0)};
  }
`
