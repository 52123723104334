import DropDown, { Option } from 'design/components/DropDown/DropDown'
import React from 'react'
import { PurchasedSku } from '~/bb-api/schemata'

interface RecipesDropdownProps {
  recipeCuts: PurchasedSku[]
  searchKeywordHandler: (event: Option) => void
}

const RecipesDropdown: React.FC<RecipesDropdownProps> = ({
  recipeCuts,
  searchKeywordHandler,
}) => {
  const cuts: Option[] = recipeCuts.map((el) => {
    return {
      label: el.description,
      value: el.description,
      'aria-label': el.description,
      id: el.sku,
    }
  })

  const clickHandler = (dropdownSelectedItem: Option) => {
    searchKeywordHandler(dropdownSelectedItem)
  }

  return <DropDown onClickHandlerCallback={clickHandler} options={cuts} />
}

export default RecipesDropdown
