import {
  IconAnimalChicken,
  IconAnimalCow,
  IconAnimalPig,
  IconAnimalFish,
  IconAnimalTurkey,
  IconBacon,
  IconPreparedMeat,
} from '@butcherbox/freezer'
import React from 'react'
import { ProteinType } from '~/bb-api/schemata'
interface ProteinTypeIconProps {
  iconName: ProteinType['type']
}
const ProteinTypeIcon: React.FC<ProteinTypeIconProps> = ({ iconName }) => {
  switch (iconName) {
    case 'beef':
      return <IconAnimalCow size="large" variant="inherit" />
    case 'pork':
      return <IconAnimalPig size="large" variant="inherit" />
    case 'chicken':
      return <IconAnimalChicken size="large" variant="inherit" />
    case 'seafood':
      return <IconAnimalFish size="large" variant="inherit" />
    case 'turkey':
      return <IconAnimalTurkey size="large" variant="inherit" />
    case 'prepared':
      return <IconPreparedMeat size="large" variant="inherit" />
    default:
      return <IconBacon size="large" variant="inherit" />
  }
}

export default ProteinTypeIcon
