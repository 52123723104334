import { rem, styled } from 'design'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import React from 'react'
import { PurchasedSku } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import ReviewCutsFullListUI from './ReviewCutsFullList.ui'

interface ReviewCutsFullListProps {
  purchasedSkusFullList: PurchasedSku[]
  filter: 'quantity' | 'total_lbs'
}
const ReviewCutsFullList: React.FC<ReviewCutsFullListProps> = ({
  purchasedSkusFullList,
  filter,
}) => {
  const [showList, setShowList] = React.useState<boolean>(false)

  const onClickHandler = () => {
    setShowList(true)
  }
  return (
    <ContainerDiv data-testid="review-cuts-full-list">
      {showList ? (
        <FullListContainer
          data-cy={TEST_ID.ACCOUNT_MEMBERSHIP_REVIEW_FULL_CUT_LIST}
          data-testid="review-cuts-full-list-section"
        >
          {purchasedSkusFullList.slice(4, 12).map((item, idx) => {
            return (
              <ReviewCutsFullListUI
                filter={filter}
                key={idx}
                rank={idx + 5}
                stat={item}
              />
            )
          })}
        </FullListContainer>
      ) : (
        <LargeButtonPrimary
          data-cy={TEST_ID.BUTTON_EXPAND_CUT_LIST}
          data-testid="review-cuts-full-list-button"
          marginTop={rem(32)}
          onClick={onClickHandler}
          px={rem(32)}
          textAlign="center"
        >
          VIEW FULL LIST OF CUTS
        </LargeButtonPrimary>
      )}
    </ContainerDiv>
  )
}

export default ReviewCutsFullList

export const FullListContainer = styled.div`
  margin-top: ${rem(32)};
  background-color: white;
  box-shadow: ${(p) => p.theme.shadows.shadow};
  ${(p) => p.theme.media.tablet} {
    box-shadow: none;
  }
`
export const ContainerDiv = styled.div`
  text-align: center;
`
