import { Option } from 'design/components/DropDown/DropDown'
import find from 'lodash.find'
import React from 'react'
import { JustCookSkuAndTags } from '~/data/justcook-tags-data'
import { JustCookTagsRecipe } from '~/data/types'
import useJustCookSearch from '~/hooks/useJustCookSearch'
import { ReviewRecipeProps } from './ReviewRecipe.types'
import ReviewRecipesSaveUnsave from './ReviewRecipesSaveUnSave'

const ReviewRecipes: React.FC<ReviewRecipeProps> = ({ topTwelveCuts }) => {
  const [searchRecipeTagId, setSearchRecipeTagId] = React.useState<number>()
  const [purchasedSku, setPurchasedSku] = React.useState<JustCookTagsRecipe>(
    null
  )

  const { data: searchedTagRecipes, isLoading } = useJustCookSearch({
    perPage: 6,
    tagIds: [searchRecipeTagId],
    enabled: !!searchRecipeTagId,
  })

  const searchRecipeTagIdHandler = (selectedSku: Option) => {
    const filteredSku = find<JustCookTagsRecipe>(JustCookSkuAndTags, [
      'sku',
      selectedSku.id,
    ])

    if (filteredSku && filteredSku.tag_id) {
      setPurchasedSku(filteredSku)
      return setSearchRecipeTagId(filteredSku.tag_id)
    }
  }

  React.useEffect(() => {
    if (topTwelveCuts[0]) {
      searchRecipeTagIdHandler({
        value: topTwelveCuts[0].description,
        label: topTwelveCuts[0].description,
        id: topTwelveCuts[0].sku,
      })
    }
  }, [topTwelveCuts])

  return (
    <ReviewRecipesSaveUnsave
      cuts={topTwelveCuts.slice(0, 12)}
      isLoading={isLoading}
      onKeywordChange={searchRecipeTagIdHandler}
      purchasedSku={purchasedSku}
      recipesList={searchedTagRecipes?.posts}
    />
  )
}

export default ReviewRecipes
