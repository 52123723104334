import { Collapse, CollapseProps } from '@chakra-ui/core'
import {
  IconChevronDown,
  IconChevronUp,
  IconProps,
  OneColorSVGProps,
  Button,
  ButtonProps,
  Box,
} from '@butcherbox/freezer'
import React from 'react'

type CollapseComponentProps = {
  buttonStyleProps?: ButtonProps & { 'data-cy': string | undefined }
  collapsedText: React.ReactChild
  expandedText: React.ReactChild
  iconStyleProps?: Omit<IconProps, 'name'>
  isOpen: boolean
  onClick: () => void
} & CollapseProps

export default function CollapseComponent({
  children,
  collapsedText,
  expandedText,
  isOpen,
  buttonStyleProps,
  iconStyleProps,
  onClick,
  ...props
}: CollapseComponentProps) {
  const chevronIconProps: IconProps<OneColorSVGProps> = {
    customColor: { base: 'spicedCrimson' },
    display: 'inlineBlock',
    marginLeft: 4,
    size: 'fixed',
    pixelSize: 12,
    verticalAlign: 'baseline',
  }
  return (
    <>
      <Box
        alignItems="center"
        as="button"
        color={{ default: 'spicedCrimson', hover: 'crimson' }}
        marginBottom={isOpen ? 16 : 0}
        onClick={onClick}
        textAlign={'left'}
        userSelect={'none'}
      >
        <Button
          color={{ default: 'spicedCrimson', hover: 'crimson' }}
          textVariant={'Body1Regular'}
          variant={'text'}
          {...buttonStyleProps}
        >
          {isOpen ? expandedText : collapsedText}
          {isOpen ? (
            <IconChevronUp {...chevronIconProps} {...iconStyleProps} />
          ) : (
            <IconChevronDown {...chevronIconProps} {...iconStyleProps} />
          )}
        </Button>
      </Box>

      <Collapse isOpen={isOpen} {...props}>
        {children}
      </Collapse>
    </>
  )
}
