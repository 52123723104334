import { Box, ButtonLink } from '@butcherbox/freezer'
import React from 'react'
import { getValidSkuForActualSku } from '~/routes/AccountBrowseRecipes/proteinAndCutsData'
import { JustCookTagsRecipe } from '~/data/types'

export const SeeMoreRecipesCTA = ({
  currentCut,
}: {
  currentCut: JustCookTagsRecipe
}) => {
  const sku = React.useMemo(() => getValidSkuForActualSku(currentCut.sku), [
    currentCut.sku,
  ])

  const browseRecipesUrl = `/account/recipes?sku=${sku}`

  return (
    <Box
      alignItems="flexStart"
      flexDirection="column"
      justifyContent="flexStart"
      paddingTop={20}
      width="100%"
    >
      <ButtonLink href={browseRecipesUrl}>See more recipes</ButtonLink>
    </Box>
  )
}
