import { rem, styled } from 'design'
import CardBadge from 'design/components/CardBadge/CardBadge'
import { CardHTMLImage } from 'design/components/CardImage/CardImage'
import { H5 } from 'design/components/Typography/Typography'
import { CardNumbers } from './CardNumbers'
import { theme } from '@butcherbox/freezer'

const CARD_IMAGE_HEIGHT = {
  DESKTOP: 270,
  MOBILE: 207,
}

export const Container = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  box-shadow: ${(p) => p.theme.shadows.shadow};
}
`

export const ContentRankContainer = styled.div`
  padding: ${rem(16)};
  display: flex;
`

export const Badge = styled(CardBadge)`
  height: ${rem(80)};
  position: absolute;
  top: ${rem(-15)};
  width: ${rem(80)};
  ${(p) => p.theme.media.tablet} {
    top: ${rem(-20)};
  }
`

export const CardImage = styled(CardHTMLImage)`
  max-width: '100%';
  min-height: ${rem(CARD_IMAGE_HEIGHT.MOBILE)};
  ${(p) => p.theme.media.tablet} {
    max-width: ${rem(406)};
    min-height: ${rem(CARD_IMAGE_HEIGHT.DESKTOP)};
  }
`

export const SkuDescription = styled.div`
  align-items: center;
  display: flex;
  height: ${rem(48)};
  justify-content: left;
`

export const QuantityLabel = styled(H5)`
  align-self: flex-start;
  background-color: ${(p) => p.theme.colors.bb.openSea};
  border-radius: ${rem(4)};
  color: white;
  padding: ${rem(5)} ${rem(8)};
  text-align: center;
`

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const CutRank = styled(CardNumbers)`
  color: ${(p) => p.theme.colors.bb.spicedCrimson};
  padding-right: ${rem(16)};
  text-align: left;
  font-family: ${theme.fonts.display};
`
