import { Box, PseudoBox } from '@chakra-ui/core'
import { rem } from 'design'
import find from 'lodash.find'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import DropDownIcon from './DropDownIcon'

export type Option = {
  'aria-label'?: string
  label: string
  value: string
  id: number
}
interface DropDownProps {
  options: Option[]
  onClickHandlerCallback: (dropdownSelected: Option) => void
}

const DropDown: React.FC<DropDownProps> = ({
  options,
  onClickHandlerCallback,
}) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSku = find<Option>(options, [
      'id',
      parseInt(event.target.value),
    ])

    onClickHandlerCallback(selectedSku)
  }

  return (
    <Box
      data-what="select"
      marginBottom={rem(8)}
      marginTop={rem(24)}
      marginX="auto"
      maxWidth={rem(343)}
      pos="relative"
    >
      <DropDownIcon />

      <PseudoBox
        _focus={{ borderColor: 'bb.spicedCrimson', boxShadow: 'outline' }}
        _hover={{ borderColor: 'bb.spicedCrimson' }}
        appearance="none"
        as="select"
        bg="white"
        border="1px solid"
        borderColor="bb.granite"
        borderRadius={0}
        cursor="pointer"
        data-cy={TEST_ID.DROPDOWN}
        data-testid="dropdown"
        data-what="select"
        height={rem(48)}
        lineHeight={rem(24)}
        onChange={onChangeHandler}
        outline="none"
        paddingLeft={rem(16)}
        paddingRight={rem(32)}
        //@ts-ignore
        textOverflow="inherit"
        w="100%"
        whiteSpace="normal"
        wordWrap="break-word"
      >
        {options.map((option) => (
          <option
            aria-label={option['aria-label']}
            data-testid="dropdown-list-item"
            data-what="select-option"
            key={option.id}
            value={option.id}
          >
            {option.label}
          </option>
        ))}
      </PseudoBox>
    </Box>
  )
}

export default DropDown
