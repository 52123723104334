import { styled, rem } from 'design'
import React from 'react'

const DropDownIcon = () => {
  return <Icon />
}

export default DropDownIcon

export const Icon = styled.div`
  cursor: pointer;
  &::after {
    content: '';
    background: ${(p) => p.theme.colors.bb.granite};
    position: absolute;
    top: ${rem(0)};
    right: ${rem(31)};
    transform: translateY(16px);
    height: ${rem(16)};
    width: 1px;
    z-index: 1;
  }
  &::before {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${(p) => p.theme.colors.bb.granite};
    transform: translateY(22px);
    position: absolute;
    z-index: 1;
    top: ${rem(0)};
    right: ${rem(16)};
  }
`
