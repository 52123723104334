import { Box } from '@chakra-ui/core'
import { rem } from 'design'
import { H3Book } from 'design/components/Typography/Typography'
import React from 'react'
import { PurchasedSku } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import * as Styled from './ReviewTopFourCuts.styles'

interface ReviewTopFourCutsUIProps {
  purchasedSku: PurchasedSku
  rank: number
  label: string
}

const ReviewTopFourCutsUI: React.FC<ReviewTopFourCutsUIProps> = ({
  purchasedSku,
  rank,
  label,
}) => {
  return (
    <Styled.Container
      data-cy={TEST_ID.CARD_RANKED_TOP_FOUR}
      data-cy-cuts-rank={rank + 1}
      data-cy-sku={purchasedSku.sku}
    >
      <Box as="div" position="relative">
        {rank === 0 && (
          <Styled.Badge
            svgProps={{
              position: 'absolute',
              left: rem(-10),
            }}
            textProps={{
              lineHeight: '16px',
              fontStyle: 'italic',
              left: '38%',
              transform: 'translate(-50%,-50%) rotate(-15deg)',
            }}
          >
            Your Top Cut
          </Styled.Badge>
        )}
        <Styled.CardImage
          as="div"
          href={purchasedSku.image}
          role="presentation"
        />
      </Box>
      <Styled.ContentRankContainer>
        <Styled.CutRank>{(rank + 1).toString()}</Styled.CutRank>

        <Styled.QuantityContainer>
          <Styled.SkuDescription>
            <H3Book overflowWrap="break-word">
              {purchasedSku.description}
            </H3Book>
          </Styled.SkuDescription>

          <Styled.QuantityLabel
            data-cy={TEST_ID.ACCOUNT_MEMBERSHIP_REVIEW_FILTER_LABEL}
            data-testid="review-card-cut-quantity"
          >
            {label}
          </Styled.QuantityLabel>
        </Styled.QuantityContainer>
      </Styled.ContentRankContainer>
    </Styled.Container>
  )
}

export default ReviewTopFourCutsUI
