import { Grid } from '@chakra-ui/core'
import { rem } from 'design'
import Round from 'lodash.round'
import React from 'react'
import { PurchasedSku } from '~/bb-api/schemata'
import ReviewTopFourCutsUI from './ReviewTopFourCuts.ui'

const parseFilter = (filter, stat: PurchasedSku) => {
  switch (filter) {
    case 'total_lbs':
      return `${Round(stat.total_lbs, 2)} lbs`
    case 'quantity':
      return `received ${stat.quantity}x`
    default:
      return ''
  }
}
interface ReviewTopFourCutsProps {
  topFourPurchasedSkus: PurchasedSku[]
  filter: 'quantity' | 'total_lbs'
}
const ReviewTopFourCuts: React.FC<ReviewTopFourCutsProps> = ({
  topFourPurchasedSkus,
  filter,
}) => {
  return (
    topFourPurchasedSkus.length > 0 && (
      <Grid
        data-testid="review-top-four-cuts"
        gridGap={rem(16)}
        gridTemplateColumns={{ tablet: 'repeat(auto-fill, minmax(45%, 1fr))' }}
        margin="0 auto"
      >
        {topFourPurchasedSkus.slice(0, 4).map((stat, idx) => {
          return (
            <ReviewTopFourCutsUI
              key={idx}
              label={parseFilter(filter, stat)}
              purchasedSku={stat}
              rank={idx}
            />
          )
        })}
      </Grid>
    )
  )
}

export default ReviewTopFourCuts
