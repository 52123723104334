import { JustCookTagsRecipe, MeatGroup } from '~/data/types'
import { startCase } from 'lodash'
import {
  CutId,
  DataCutsGroup,
  UICutsGroup,
} from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'
import { JustCookSkuAndTags } from '~/data/justcook-tags-data'

/* CUTS */
/**
 * Reduces a list of ButcherBox cuts to a consolidated list of "cut types".
 *
 * The `slug` property is used as the uniquifying key as well as for the `description` for the consolidated entry.
 *
 * e.g., cuts with description "ButcherBox Bacon" and "Tender Belly Bacon" will be consolidated into a single entry
 * with description "Bacon".
 *
 * Note that this will replace a cut's description with its slug even if it's the only cut of its type.
 *
 * e.g., `{ description: 'Boneless Chicken Thighs', slug: 'chicken-thighs' }` will become `{ description: 'Chicken Thighs', slug: 'chicken-thighs' }`
 * regardless of whether we have any other types of chicken thighs.
 * @param allCuts The cuts to be consolidated
 */
export const getUniqueCutsBySlug = (
  allCuts: JustCookTagsRecipe[]
): JustCookTagsRecipe[] =>
  allCuts.reduce((arr, cut) => {
    const existingCut = arr.find((c) => c.slug === cut.slug)
    if (existingCut) return arr

    return arr.concat({
      ...cut,
      description: startCase(cut.slug),
    })
  }, [])

/**
 * Reduces a list of cuts to an array of meat-group objects, each with a `group` property to describe the group, and an
 * `items` property with a list of items that fall within that meat group. The returned `items` remain in their
 * `JustCookTagsRecipe` form.
 *
 * @param cuts The list of cuts to be grouped. Note that this function doesn't know or care whether or not the cuts have
 * been processed by `getUniqueCutsBySlug`.
 * @param meatGroupIds If this parameter is *not* supplied, the groups will be derived from the groups represented
 * in the `cuts` parameter. i.e., `cuts.map(c => c.meatGroup) === returnValue.map(g => g.group.id)`
 *
 * If this parameter *is* provided, the cuts will be sorted into the provided groups, and all cuts with a meat group
 * not present in the `meatGroupIds` parameter will be sorted into a group with `{ id: 'other', label: 'other' }`
 */
export const getCutsByMeatGroup = (
  cuts: JustCookTagsRecipe[],
  meatGroupIds?: MeatGroup[]
): DataCutsGroup[] => {
  // use provided meat groups, or distill the list of meat groups from the cuts
  const meatGroups = meatGroupIds || [...new Set(cuts.map((c) => c.meatGroup))]

  const mainGroups = meatGroups.map((meatGroupId) => ({
    group: {
      id: meatGroupId,
      label: startCase(meatGroupId),
    },
    items: cuts.filter((cut) => cut.meatGroup === meatGroupId),
  }))

  if (!meatGroupIds) {
    return mainGroups
  }

  const otherGroup: DataCutsGroup = {
    group: {
      id: 'other',
      label: 'Other',
    },
    items: cuts.filter((cut) => !meatGroupIds.includes(cut.meatGroup)),
  }

  return otherGroup.items.length ? [...mainGroups, otherGroup] : mainGroups
}

/**
 * Reduces a list of cuts to a form appropriate for use in the `CutsFilter` of the `AccountBrowseRecipes` page.
 *
 * The list is consolidated by cut type (`slug`) and grouped into the main meat groups (beef, pork, chicken,
 * seafood) and an "other" group.
 *
 * This function accepts parameters, for both the list of cuts and the list of meat groups, so that it can be tested
 * without a dependency on the hardcoded list of cuts.
 * @param cuts
 * @param meatGroupIds
 */
export const getUiCutsGroups = (
  cuts: JustCookTagsRecipe[] = JustCookSkuAndTags,
  meatGroupIds: MeatGroup[] = mainProteins
) => {
  const allUniqueCuts: JustCookTagsRecipe[] = getUniqueCutsBySlug(cuts)
  const cutsGroups: DataCutsGroup[] = getCutsByMeatGroup(
    allUniqueCuts.sort((a, b) => (a.description > b.description ? 1 : -1)),
    meatGroupIds
  )

  const uiCutsGroups: UICutsGroup[] = cutsGroups.map((group) => ({
    ...group,
    items: group.items.map(({ description, sku }) => ({
      id: sku,
      label: description,
    })),
    itemIds: group.items.map((item) => item.sku),
  }))
  return { uiCutsGroups, allUniqueCuts }
}

export const JustCookSkuToTagIdMap = JustCookSkuAndTags.reduce(
  (obj, cut) => ({
    ...obj,
    [cut.sku]: { tagId: cut.tag_id, slug: cut.slug },
  }),
  {}
)

export const mainProteins: MeatGroup[] = [
  'beef',
  'pork',
  'chicken',
  'turkey',
  'seafood',
]
export const { uiCutsGroups, allUniqueCuts } = getUiCutsGroups(
  JustCookSkuAndTags,
  mainProteins
)

export const getValidSkuForActualSku = (sku: CutId) => {
  const mapEntry = JustCookSkuToTagIdMap[sku]
  if (!mapEntry) return null
  const { tagId } = mapEntry
  const cutWithValidSku = allUniqueCuts.find((cut) => cut.tag_id === tagId)
  if (!cutWithValidSku) return null
  return cutWithValidSku.sku
}
