import React from 'react'
import { useQuery } from 'react-query'
import { CACHE_KEY_CUSTOMER_STATS } from '~/bb-api/constants'
import { GET_CUSTOMER_STATS } from '~/bb-api/endpoints'
import { CustomerStats } from '~/bb-api/schemata'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'

export default function useCustomerStats() {
  const { id } = React.useContext(UserContext)

  return useQuery(
    [CACHE_KEY_CUSTOMER_STATS, id],
    () =>
      axios.get<CustomerStats>(GET_CUSTOMER_STATS(id)).then((res) => res.data),
    { staleTime: Infinity }
  )
}
