import { rem, styled } from 'design'

// On both desktop and Mobile, the panel with the content
// overlaps the hero by a different amount, these are used
// internally, as well as within the container to offset
export const HERO_OVERLAP = {
  DESKTOP: 75,
  MOBILE: 30,
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 100%;
  // The metrics w/i figma are not aligned to any guides or
  // pixels. 1300px is about the size defined in the design
  max-width: ${rem(1300)};
  // Center the enter unit in the space
  margin: 0 auto;
  // This bottom accounts for the overlap + space below the text
  padding-bottom: ${rem(HERO_OVERLAP.MOBILE + 40)};
  // head space for the design
  padding-top: ${rem(90)};
  // This aligns to the parent containers left and right margins
  // keeping the copy in vertical alignment with the next unit
  padding-left: ${rem(16)};
  padding-right: ${rem(16)};
  // Create space before the copy for the imagery
  &:before {
    content: '';
    display: block;
    padding-top: 50%;
  }
  /**
   * Tablet Up Overrides
   **/
  ${(p) => p.theme.media.tablet} {
    // The overlap is much larger on desktop
    padding-bottom: ${rem(HERO_OVERLAP.DESKTOP + 90)};
    // The photo is on the right on desktop
    padding-top: ${rem(90)};
    &: before {
      display: none;
    }
    & > * {
      // The text elements should never cross the right side of the
      // space as that is reserved for supporting artwork
      max-width: 50%;
    }
  }
`
