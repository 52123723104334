import { Text } from '@butcherbox/freezer'
import { Grid } from '@chakra-ui/core'
import { rem, styled } from 'design'
import React from 'react'
import { CustomerStats, PurchasedSku } from '~/bb-api/schemata'
import ProteinTypeIcon from './ProteinTypeIcon'
import * as Styled from './ReviewStatsHighlight.styles'

interface ReviewStatsHighlightProps {
  stats: CustomerStats
}

export const getTotalPounds = (skus: PurchasedSku[]) => {
  const total = skus.reduce((prev, curr) => prev + curr.total_lbs, 0)

  return Math.abs(total) > 999
    ? `${(Math.abs(total) / 1000).toFixed(1)}k`
    : Math.abs(total).toFixed()
}
const ReviewStatsHighlight: React.FC<ReviewStatsHighlightProps> = ({
  stats,
}) => {
  if (stats.orderCount > 0) {
    return (
      <Grid
        data-testid="review-stats-highlight"
        gridGap={rem(16)}
        gridTemplateColumns={{ base: 'auto', tablet: '32% 32% 32%' }}
        marginBottom={rem(16)}
        textAlign="center"
      >
        <Styled.Container>
          <Styled.StatsContainer data-testid="review-stats-order-count">
            {stats.orderCount}
          </Styled.StatsContainer>

          <Text
            textAlign={{ tablet: 'center', mobile: 'left' }}
            variant="H3Regular"
          >
            boxes delivered directly to your door
          </Text>
        </Styled.Container>

        <Styled.Container>
          <Styled.StatsContainer data-testid="review-stats-total-lbs">
            {stats.purchasedSkus.length > 0
              ? getTotalPounds(stats.purchasedSkus)
              : 0}
          </Styled.StatsContainer>

          <Text
            textAlign={{ mobile: 'left', tablet: 'center' }}
            variant="H3Regular"
          >
            pounds of high-quality meat for you
          </Text>
        </Styled.Container>

        <Styled.Container>
          <Styled.MeatStyleContainer>
            <ProteinTypeIcon iconName={stats.proteinTypes[0]?.type} />
          </Styled.MeatStyleContainer>
          <Text
            textAlign={{ tablet: 'center', mobile: 'left' }}
            variant="H3Regular"
          >
            your most ordered protein is{' '}
            <ProteinTypeText data-testid="review-stats-protein-type">
              {stats.proteinTypes[0]?.type || 'None'}
            </ProteinTypeText>
          </Text>
        </Styled.Container>
      </Grid>
    )
  }
}

export default ReviewStatsHighlight

const ProteinTypeText = styled.span`
  text-transform: capitalize;
`
