import type { RouteComponentProps } from '@reach/router'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import useCustomerStats from '~/hooks/useCustomerStats'
import ReviewCutsAndRecipes from './ReviewCutsAndRecipes/ReviewCutsAndRecipes'
import ReviewHeader from './ReviewHeader/ReviewHeader'

const AccountMembershipReview: React.FC<RouteComponentProps> = () => {
  const { data, isLoading } = useCustomerStats()
  return (
    <>
      <ReviewHeader />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ReviewCutsAndRecipes customerStats={data} />
      )}
    </>
  )
}

export default AccountMembershipReview
