import { CutsFilterGroup } from '~/routes/AccountBrowseRecipes/AccountBrowseRecipes.types'
import { useJustCookQuery } from '~/hooks/useJustCookQuery'

export const CATEGORY_MAP = {
  all: '4345',
  beef: '106',
  chicken: '129',
  pork: '124',
  turkey: '4160',
  seafood: '4545',
  other: '333&categories_exclude=4545',
}

type PageProps = {
  perPage?: number
  page?: number
}

// one or both of `category`, `searchValues`
type SearchOptions =
  | {
      categories: CutsFilterGroup[]
      tagIds: number[]
    }
  | {
      categories: CutsFilterGroup[]
      tagIds?: never
    }
  | {
      tagIds: number[]
      categories?: never
    }

type QueryConfigProps = {
  enabled?: boolean
}

export default function useJustCookSearch({
  tagIds,
  categories,
  perPage = 100,
  page = 1,
  enabled = true,
}: SearchOptions & PageProps & QueryConfigProps) {
  let searchUrl
  if (!categories?.length && !tagIds?.length) {
    const allCategories = Object.values(CATEGORY_MAP).map(
      (str) => str.split('&categories_exclude')[0]
    )
    searchUrl = `categories=${allCategories}`
  } else {
    const tagIdsStr = tagIds?.length ? `tags=${tagIds}` : ''
    const categoryStr = categories?.length
      ? `categories=${categories?.map(
          (cat) => CATEGORY_MAP[cat as keyof typeof CATEGORY_MAP]
        )}`
      : ''

    searchUrl = [categoryStr, tagIdsStr].filter(Boolean).join('&')
  }

  return useJustCookQuery({
    searchUrl,
    orderBy: 'date',
    page,
    perPage,
    enabled,
  })
}
