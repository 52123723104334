import { rem, styled } from 'design'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { HERO_OVERLAP } from '../ReviewHeader/ReviewHeader.styles'

const BOTTOM_MARGIN = {
  DESKTOP: 295,
  MOBILE: 16,
}

const PAGE_MARGIN = 16

export const ContainerBackground = styled(ImageSetBackground)``
export const ContainerSection = styled.div`
  // the contents will dictate the height, set a min value to help visualize when empty
  min-height: 50vh;
  max-width: ${rem(985)};
  text-align: center;
  // hoist the container over the hero space
  position: relative;
  top: ${rem(HERO_OVERLAP.MOBILE * -1)};
  width: 100%;
  // we always want the content to be offset from the edges of the browser
  margin-left: ${rem(PAGE_MARGIN)};
  margin-right: ${rem(PAGE_MARGIN)};

  // On mobile there is a much smaller gap than on desktop after the content
  // We remove the overlap from this metric as the offset is done with
  // relative positioning
  // removed feedbackform margin and added here - 80
  margin-bottom: ${rem(BOTTOM_MARGIN.MOBILE - HERO_OVERLAP.MOBILE + 80)};

  ${(p) => p.theme.media.tablet} {
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
    background-color: white;
    border: 6px solid ${(p) => p.theme.colors.bb.spicedCrimson};
    // offset the content from the frame edge
    padding: ${rem(56)} ${rem(72)} ${rem(88)} ${rem(72)};
    // Adjust the top offset
    top: ${rem(HERO_OVERLAP.DESKTOP * -1)};
    // Desktop has a lot of breathing room after the content
    margin-bottom: ${rem(BOTTOM_MARGIN.DESKTOP - HERO_OVERLAP.DESKTOP)};
  }
`

export const Break = styled(PanelBreak)`
  margin: ${rem(80)} auto ${rem(49)};
  ${(p) => p.theme.media.tablet} {
    margin: ${rem(64)} auto ${rem(49)};
  }
`
