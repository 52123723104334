import React from 'react'
import CustomerStatsFilterUI from './CustomerStatsFilter.ui'

const CustomerStatsFilter = ({ filterChange, filter }) => {
  return (
    <CustomerStatsFilterUI filter={filter} handleFilterChange={filterChange} />
  )
}

export default CustomerStatsFilter
