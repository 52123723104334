import { Flex } from '@chakra-ui/core'
import { css } from '@emotion/react'
import { rem } from 'design'
import Radio from 'design/components/Radio/Radio'
import { Body } from 'design/components/Typography/Typography'
import { nanoid } from 'nanoid'
import React from 'react'
import { RadioWithLabelProps } from '~/routes/CheckoutFlow/BoxSizeFrequencyFrame/BoxSizeFrequency.types'

export const RadioWithLabel: React.FC<RadioWithLabelProps> = React.forwardRef(
  ({ label, labelProps, ...props }, forwardedRef) => {
    const inputId = `${nanoid()}-label`

    return (
      <Flex
        {...labelProps}
        align="center"
        as="label"
        css={{ '& + *': { marginTop: rem(12) } }}
        cursor="pointer"
        data-what="radio-with-label"
        //@ts-ignore
        htmlFor={inputId}
      >
        <Radio
          {...props}
          inputId={inputId}
          mr={rem(12)}
          radioStyleProps={{
            css: css`
              input:focus-visible + & {
                outline: 5px auto Highlight; // Firefox
                outline: 5px auto -webkit-focus-ring-color; // Chromium & Safari
              },
            `,
          }}
          ref={forwardedRef}
          size="lg"
        />
        <Body aria-hidden>{label}</Body>
      </Flex>
    )
  }
)
