import { Text, Lockup } from '@butcherbox/freezer'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import * as Styled from './ReviewHeader.styles'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'
import { UserContext } from '~/context/user'
import dayjs from 'dayjs'

function ReviewHeader() {
  const user = React.useContext(UserContext)
  const imageData = useStaticQuery(graphql`
    query {
      bg: contentfulImageSet(
        uniqueId: { eq: "membership_review_hero_background" }
      ) {
        ...ImageSet
      }
    }
  `)
  return (
    <ImageSetBackground
      breakpoints={{ mobile: '(max-width: 767px)' }}
      imageSet={imageData.bg}
    >
      <Styled.Container>
        <Lockup>
          <Text color="white" component="span" variant="Subhead1">
            Member Since {dayjs(user.joinedAt).format('MMMM D, YYYY')}
          </Text>
          <Text color="white" component="h1" variant="DisplayOne">
            Welcome, {user.firstName}!
          </Text>
          <Text color="white" component="h2" variant="BodyH2Bold">
            Here are a few highlights from your ButcherBox membership.
          </Text>
        </Lockup>
      </Styled.Container>
    </ImageSetBackground>
  )
}

export default ReviewHeader
