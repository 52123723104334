import { graphql, useStaticQuery } from 'gatsby'
import { isEmpty, orderBy } from 'lodash'
import React from 'react'
import { CustomerStats, PurchasedSku } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import { SKU_EXCLUSIONS } from '~/constants/skuExclusions'
import useLatestShipmentStatus from '~/hooks/useLatestShipmentStatus'
import { BaseNotFoundPage } from '~/routes/404/BaseNotFoundPage'
import { combineSkus } from '~/utils/combineskus'
import FeedbackForm from '../../../components/FeedbackForm/FeedbackForm'
import CustomerStatsFilter from './CustomerStatsFilter/CustomerStatsFilter'
import * as Styled from './ReviewCutsAndRecipes.styles'
import ReviewCutsFullList from './ReviewCutsFullList/ReviewCutsFullList'
import ReviewRecipes from './ReviewRecipes/ReviewRecipes'
import ReviewStatsHighlight from './ReviewStatsHighlight/ReviewStatsHighlight'
import ReviewTopFourCuts from './ReviewTopFourCuts/ReviewTopFourCuts'

interface ReviewCutsAndRecipesProps {
  customerStats: CustomerStats
}

const noItemsMessage =
  'Since we haven’t shipped your first box yet, we don’t have anything to show you. ' +
  'Please check back in after you get your shipment notification for some useful information and recipes based on the contents of your box.'

const ReviewCutsAndRecipes: React.FC<ReviewCutsAndRecipesProps> = ({
  customerStats,
}) => {
  const backgroundImage = useStaticQuery(graphql`
    query {
      containerBG: contentfulImageSet(
        uniqueId: { eq: "memebership_review_background" }
      ) {
        ...ImageSet
      }
    }
  `)

  const [skus, setSkus] = React.useState<PurchasedSku[]>([])
  const [filter, setFilter] = React.useState<'quantity' | 'total_lbs'>(
    'quantity'
  )

  React.useEffect(() => {
    if (customerStats?.purchasedSkus) {
      setSkus(
        combineSkus(
          customerStats.purchasedSkus.filter(
            (product) => !SKU_EXCLUSIONS.includes(product.sku.toString())
          )
        )
      )
    }
  }, [customerStats])

  const filterChange = (filter) => {
    setFilter(filter)
  }

  const orderedSkus = React.useMemo(() => {
    return orderBy<PurchasedSku>(skus, [filter], ['desc'])
  }, [filter, skus])

  const { isLatestShipmentProcessing } = useLatestShipmentStatus()

  const noItemsYet =
    (customerStats.orderCount === 1 && isLatestShipmentProcessing) ||
    isEmpty(customerStats.purchasedSkus)

  return (
    <Styled.ContainerBackground
      alt="A marble stone slab"
      data-cy={TEST_ID.ACCOUNT_MEMBERSHIP_REVIEW_BACKGROUND}
      imageSet={backgroundImage.containerBG}
    >
      <Styled.ContainerSection data-where="membership-review">
        {noItemsYet ? (
          <div data-cy={TEST_ID.ACCOUNT_MEMBERSHIP_REVIEW_NO_STATS_FOUND}>
            <BaseNotFoundPage message={noItemsMessage} />
          </div>
        ) : (
          <>
            <ReviewStatsHighlight
              stats={{ ...customerStats, purchasedSkus: skus }}
            />
            <CustomerStatsFilter filter={filter} filterChange={filterChange} />
            <ReviewTopFourCuts
              filter={filter}
              topFourPurchasedSkus={orderedSkus}
            />
            <ReviewCutsFullList
              filter={filter}
              purchasedSkusFullList={orderedSkus}
            />
            <Styled.Break intensity="light" />
            <ReviewRecipes topTwelveCuts={skus} />
            <Styled.Break intensity="light" />
            <FeedbackForm formId="membership-review-feedback-form" />
          </>
        )}
      </Styled.ContainerSection>
    </Styled.ContainerBackground>
  )
}

export default ReviewCutsAndRecipes
