import { useQuery } from 'react-query'
import { JustCookData, JustCookRecipe } from '~/bb-api/schemata'

const FIELDS = [
  'jetpack_featured_media_url',
  'title.rendered',
  'excerpt.rendered',
  'link',
  'id',
]
export const makeJustCookUrl = (
  searchUrl,
  { perPage = 100, page = 1, fields = FIELDS, orderBy }
) => {
  const BASE_URL = 'https://justcook.butcherbox.com/wp-json/wp/v2/posts?'
  return (
    BASE_URL +
    [
      searchUrl,
      `_fields=${fields}`,
      `per_page=${perPage}`,
      `page=${page}`,
      `orderby=${orderBy}`,
      'tax_relation=OR',
    ].join('&')
  )
}
type JustCookQueryConfig = {
  searchUrl: string
  page: number
  enabled: boolean
  orderBy?: 'date' | 'include'
  perPage: number
}

export const useJustCookQuery = ({
  searchUrl,
  page,
  orderBy = 'date',
  perPage,
  enabled,
}: JustCookQueryConfig) => {
  const cacheKey = ['justCook', searchUrl, page]
  const url = makeJustCookUrl(searchUrl, { orderBy, page, perPage })
  return useQuery<JustCookData>(
    cacheKey,
    () => {
      let headers
      return fetch(url, { mode: 'cors' })
        .then((res) => {
          headers = res.headers
          return res.json() as Promise<JustCookRecipe[]>
        })
        .then((posts) => ({
          posts,
          totalPages: Number(headers.get('x-wp-totalpages')),
          totalPosts: Number(headers.get('x-wp-total')),
        }))
    },
    {
      staleTime: Infinity,
      keepPreviousData: true,
      enabled,
    }
  )
}
