import { PseudoBox } from '@chakra-ui/core'
import { ITypography } from 'design/components/Typography/Typography'
import { rem } from 'design/utils'
import React from 'react'

export const CardNumbers: ITypography = (props) => {
  return (
    <PseudoBox
      as="h2"
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(96)}
      fontWeight="500"
      lineHeight={rem(90)}
      {...props}
    />
  )
}
